@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
} */

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.scrollbar-hide {
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  scrollbar-width: none; /* For Firefox */
}

/* 
:root {
  --background: #ffffff;
  --foreground: #171717;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: Arial, Helvetica, sans-serif;
} */
